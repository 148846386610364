import $ from 'jquery';
/* global ajax_object */
$(document).ready(function () {
  popUpWidget();
});

function popUpWidget() {
  $('.js-team-popup-widget').each(function () {
    $(document).on('click', '.team-loop a', function () {
      let parents = $(this).parents('.team-loop');
      var postId = parents.attr('id');
      loadPostContent(postId);
    });

    function loadPostContent(postId) {
      $.ajax({
        url: ajax_object.ajax_url,
        type: 'GET',
        data: {
          action: 'get_team_post_content',
          post_id: postId,
        },
        success: function (response) {
          $('.post-content').html(response);
        },
        error: function (xhr) {
          console.error(xhr.responseText);
          console.log('error');
        },
      });
    }
  });
}
